<template>
  <img
    src="../assets/img/goback1.png"
    alt=""
    @click="onClickLeft"
    class="goback"
  />
  <div class="loginBox">
    <div class="accountTitle">
      <img src="../assets/img/image.png" alt="" />
      <ul>
        <li class="fontTitle">快乐星球</li>
        <li class="englishTitle">HAPPY STAR</li>
      </ul>
    </div>
    <p class="tableTitle">SING IN</p>

    <van-cell-group inset class="cellTable">
      <van-cell value="Email Address" />
      <van-field
        v-model="from.account"
        label=""
        clearable
        border="true"
        maxlength="30"
        left-icon="envelop-o"
      />
      <p class="forgetPassword">
        <van-cell value="Password" />
        <van-button
          type="default"
          class="rightForget"
          @click="getchangePassword"
          >Forgot Password ?</van-button
        >
      </p>
      <van-field
        v-model="from.password"
        type="password"
        label=""
        clearable
        border="true"
        left-icon="lock"
        maxlength="20"
      />
    </van-cell-group>

    <div class="clickViews">
      <van-field name="checkboxGroup" label="">
        <template #input>
          <van-checkbox v-model="checked" icon-size="14px" class="checkbox">
          </van-checkbox>
          <p class="checktitle">
            <i class="iBox" @click="protocolPrivacy"
              >views terms and agreements</i
            >
          </p>
        </template>
      </van-field>
    </div>
    <div class="clickViews">
      <van-field name="checkboxGroup" label="">
        <template #input>
          <van-checkbox v-model="remember" icon-size="14px" class="checkbox">
          </van-checkbox>
          <p class="checktitle" @click="protocolPrivacy">
            <i class="iBox">Remember password</i>
          </p>
        </template>
      </van-field>
    </div>
    <van-button type="primary" class="loginButton" @click="loginLine"
      >SIGN IN
    </van-button>
    <van-button type="default" class="otherButton" @click="goRegister"
      >Not a member?<span>Sign up now</span></van-button
    >
  </div>
</template>

<script>
import "../assets/css/unified.css";
import "vant/lib/button/style";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import service from "@/utils/api";
import { reactive, toRefs, onMounted } from "@vue/runtime-core";
import urlencode from "urlencode";
import method from "../utils/codeJudgment";

export default {
  name: "loginSecond",
  props: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      from: [],
      checked: "",
      remember: false,
      includeList: [],
    });
    const onClickLeft = () => {
      router.push({ path: "/login" });
    };
    // 登录

    const loginLine = () => {
      if (state.checked == null || state.checked == "") {
        Toast.fail("You need to read and agree to our Terms before logging in");
        return false;
      }
      if (!state.from.account || !state.from.password) {
        Toast.fail("Enter mailbox password");
      } else {
        // Toast.fail("登录前");
        const params = {
          account: state.from.account,
          password: state.from.password,
        };
        service.getLogin(params).then((res) => {
          if (res.data.errcode != 0) {
            Toast.fail(method.getTextByStatus(res.data.errcode));
          } else {
            const saveData = ["saveUser"];
            saveData.push({
              name: state.from.account,
              token: res.data.data.accessToken,
              is_remeber: state.remember !== false ? 1 : 0,
            });
            const param = urlencode(JSON.stringify(saveData));
            location.href = `${process.env.VUE_APP_BASE_URL}end.html?` + param;
            sessionStorage.setItem("token", res.data.data.accessToken);
            Toast.success("Login succeeded");
          }
        });
      }
    };
    // 注册
    const goRegister = () => {
      router.push({ path: "/register" });
    };
    // 找回密码
    const getchangePassword = () => {
      router.push({ path: "/changePassword" });
    };
    // 协议指南
    const protocolPrivacy = () => {
      router.push({ path: "/agreement" });
    };

    onMounted(() => {});

    return {
      ...toRefs(state),
      loginLine,
      goRegister,
      getchangePassword,
      protocolPrivacy,
      onClickLeft,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  .goback {
    position: absolute;
    background-color: transparent;
    top: 6%;
    left: 7%;
    width: 35px;
    z-index: 999;
  }
  // 整体表单
  .loginBox {
    background-color: transparent;
    position: absolute;
    width: 37%;
    height: 100%;
    overflow: hidden;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 5% 30px 13.8% 0px;
    color: #ffffff;
    z-index: 2;
    // 快乐星球盒子
    .accountTitle {
      height: 12%;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        width: 40px;
        height: 100%;
      }
      .englishTitle {
        font-size: 10px;
      }
    }
    // SING IN
    .tableTitle {
      text-align: left;
      font-size: 20px;
    }
    // 表单背景色
    .cellTable {
      background-color: transparent !important;
      .van-cell {
        font-size: 10px;
        overflow: hidden;
        padding-left: 6px;
      }
      // 输入表单的背景色
      .van-field {
        background-image: url("../assets/img/password.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 4px;
      }
      // 忘记密码
      .forgetPassword {
        font-size: 10px;
        display: flex;
        flex-direction: row;
        .rightForget {
          width: 400px;
          height: 100%;
          line-height: 24px;
          font-size: 10px;
          border: none;
          color: #ffffff !important;
          background-color: transparent !important;
        }
      }
    }
    // 阅读指南
    .clickViews {
      height: 30px;
      overflow: hidden;
      padding: 0px;
      .van-field {
        height: 30px !important;
        overflow: hidden;
      }
      .checktitle {
        color: grey;
        overflow: hidden;
        font-size: 10px;
        width: 200px !important;
        .iBox {
          margin-left: 3px;
        }
      }
    }
    // 登录按钮
    .loginButton {
      width: 148px;
      height: 30px;
      background-color: transparent;
      background-image: url("../assets/img/account.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: none;
    }
    // 去注册
    .otherButton {
      width: 100%;
      color: #999999 !important;
      background-color: transparent !important;
      border: none;
      font-size: 10px;
      span {
        color: #ffffff;
        margin-left: 6px;
      }
    }
  }
}
</style>
